import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  state: {
    bookingsPresets: null,
    selectedDeclarations: {},
    booking: null,
    preset: null,
    declarableResources: null,
    hostId: null,
    availableHosts: null,
    cost: null,
    grid: false,
  },
  mutations: {
    setBookingsPresets(state, payload) {
      state.bookingsPresets = payload;
    },
    setSelectedDeclarations(state, payload) {
      state.selectedDeclarations = payload;
    },
    setBooking(state, payload) {
      state.booking = payload;
    },
    setPreset(state, payload) {
      state.preset = payload;
    },
    setDeclarableResources(state, payload) {
      state.declarableResources = payload;
    },
    setHostId(state, payload) {
      state.hostId = payload;
    },
    setAvailableHosts(state, payload) {
      state.availableHosts = payload;
    },
    setCost(state, payload) {
      state.cost = payload;
    },
    setShowGrid(state, payload) {
      state.grid = payload;
    },
  },
  actions: {
    getBookings(
      { commit, state, rootState },
      { resourceIds, presetId, extend }
    ) {
      let presets = null;
      if (rootState.search.presets && rootState.search.presets.length) {
        presets = rootState.search.presets.filter((el) => el.is_active);
        if (
          resourceIds &&
          resourceIds.length &&
          state.bookingsPresets &&
          state.bookingsPresets.length
        ) {
          let presetIds = [];
          state.bookingsPresets.forEach((element) => {
            if (
              element.resources &&
              element.resources.length &&
              element.resources.some((e) => resourceIds.includes(e.id))
            ) {
              presetIds.push(element.presetId);
            }
          });
          presets = presets.filter((el) => presetIds.includes(el.id));
        }
        if (presetId) {
          presets = presets.filter((el) => el.id === presetId);
        }
      }
      const url = `${apiEndpoints.company.bookings}`;
      if (presets) {
        let bookings = [];
        let promises = [];
        presets.forEach((preset) => {
          commit("loader/setScreenLoading", true, { root: true });
          const postObject = {
            preset: {
              levels: preset.preset.levels,
              resource_types: preset.preset.resource_types,
              capacity: preset.preset.capacity ? preset.preset.capacity : null,
              resources: preset.preset.resources,
              date_period: preset.preset.date_period,
              declaration_status: preset.preset.declaration_status,
              declaration_approval_status:
                preset.preset.declaration_approval_status,
              my_bookings: preset.preset.my_bookings,
            },
            query: {
              extend_period: extend ? extend : 0,
            },
          };
          const promise = new Promise((resolve, reject) => {
            return httpServiceAuth
              .post(url, postObject)
              .then((response) => {
                const bookingPresetObject = {
                  presetName: preset.name,
                  presetId: preset.id,
                  order: preset.order,
                  resources: response.data.data,
                };
                const bookingIndex = bookings.findIndex(
                  (el) => el.presetId === preset.id
                );
                if (bookingIndex !== -1) {
                  bookings[bookingIndex] = bookingPresetObject;
                } else {
                  bookings.push(bookingPresetObject);
                }
                resolve(true);
              })
              .catch((error) => {
                reject(false);
                if (error.response) {
                  errorHandler(error.response);
                }
              })
              .finally(() => {
                commit("loader/setScreenLoading", false, { root: true });
              });
          });
          promises.push(promise);
        });
        Promise.all(promises).then(() => {
          if ((resourceIds && resourceIds.length) || presetId) {
            let arr = [];
            state.bookingsPresets.forEach((element) => {
              let duplicate = bookings.find(
                (el) => el.presetId === element.presetId
              );
              if (duplicate && extend) {
                duplicate.resources = [
                  ...element.resources,
                  ...duplicate.resources,
                ];
              }
              arr.push(duplicate ? duplicate : element);
            });
            commit("setBookingsPresets", arr);
          } else {
            bookings.sort((a, b) => a.order - b.order);
            commit("setBookingsPresets", bookings);
          }
        });
      }
    },
    getBooking({ commit }, data) {
      const { id, params, hideLoader } = data;
      if (!hideLoader) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      let url = `${apiEndpoints.company.bookings}/${id}`;
      return httpServiceAuth
        .get(url, { params })
        .then((response) => {
          response.data.data.is_private = response.data.data.is_private
            ? true
            : false;
          commit("setBooking", response.data.data);
        })
        .catch(() => {})
        .finally(() => {
          if (!hideLoader) {
            commit("loader/setScreenLoading", false, { root: true });
          }
        });
    },
    setSelectedDeclarations({ commit, state }, { presetId, declarationId }) {
      if (state.selectedDeclarations[presetId]) {
        if (state.selectedDeclarations[presetId].includes(declarationId)) {
          state.selectedDeclarations[presetId] = state.selectedDeclarations[
            presetId
          ].filter((el) => el !== declarationId);
        } else {
          state.selectedDeclarations[presetId].push(declarationId);
        }
      } else {
        state.selectedDeclarations[presetId] = [declarationId];
      }
      commit("setSelectedDeclarations", state.selectedDeclarations);
    },
    getResourcesAvailability(
      { commit, state, rootState },
      { slots, preset, declarationId, hostId }
    ) {
      const url = `${apiEndpoints.company.bookings}/resources-availability`;
      if (preset) {
        commit("loader/setScreenLoading", true, { root: true });
        const postObject = {
          preset: {
            levels: preset.preset.levels,
            resource_types: preset.preset.resource_types,
            capacity: preset.preset.capacity ? preset.preset.capacity : null,
            resources: preset.preset.resources,
            date_period: preset.preset.date_period,
            declaration_status: preset.preset.declaration_status,
            declaration_approval_status:
              preset.preset.declaration_approval_status,
            my_bookings: preset.preset.my_bookings,
          },
          type: slots.type,
          slots: slots.slots,
          host_id: hostId,
        };
        return httpServiceAuth
          .post(url, postObject)
          .then((response) => {
            commit("setDeclarableResources", response.data);
          })
          .catch((error) => {
            if (error.response) {
              errorHandler(error.response);
            }
          })
          .finally(() => {
            commit("loader/setScreenLoading", false, { root: true });
          });
      } else if (declarationId) {
        // Create array of all presets ids with selected declaration
        let presetsIds = [];
        state.bookingsPresets.forEach((element) => {
          if (element.resources && element.resources.length) {
            element.resources.forEach((el) => {
              if (
                el.declarations &&
                el.declarations.length &&
                el.declarations.find((e) => e.id == declarationId) &&
                !presetsIds.find((e) => e === element.presetId)
              ) {
                presetsIds.push(element.presetId);
              }
            });
          }
        });
        let presetsArray = [];
        if (rootState.search.presets && rootState.search.presets.length) {
          presetsArray = rootState.search.presets.filter(
            (el) => el.is_active && presetsIds.includes(el.id)
          );
        }
        let resources = [];
        let promises = [];
        presetsArray.forEach((element) => {
          commit("loader/setScreenLoading", true, { root: true });
          const promise = new Promise((resolve) => {
            const postObject = {
              preset: {
                levels: element.preset.levels,
                resource_types: element.preset.resource_types,
                capacity: element.preset.capacity
                  ? element.preset.capacity
                  : null,
                resources: element.preset.resources,
                date_period: element.preset.date_period,
                declaration_status: element.preset.declaration_status,
                declaration_approval_status:
                  element.preset.declaration_approval_status,
                my_bookings: element.preset.my_bookings,
              },
              type: slots.type,
              slots: slots.slots,
              declaration_id: declarationId,
              host_id: hostId,
            };
            return httpServiceAuth
              .post(url, postObject)
              .then((response) => {
                response.data.forEach((el) => {
                  if (!resources.find((e) => e.id === el.id)) {
                    resources.push(el);
                  }
                });
              })
              .catch((error) => {
                if (error.response) {
                  errorHandler(error.response);
                }
              })
              .finally(() => {
                resolve(true);
                commit("loader/setScreenLoading", false, { root: true });
              });
          });
          promises.push(promise);
        });
        Promise.all(promises).then(() => {
          commit("setDeclarableResources", resources);
        });
      }
    },
    setDeclaration({ commit, dispatch }, postObject) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.bookings}/store`;
      return httpServiceAuth
        .post(url, postObject)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .then(() => {
          dispatch("getBookings", {
            resourceIds: postObject.resource_ids,
            presetId: null,
            extend: null,
          });
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    updateDeclaration({ commit, dispatch }, { postObject, declarationId }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.bookings}/${declarationId}`;
      return httpServiceAuth
        .put(url, postObject)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .then(() => {
          dispatch("getBookings", {
            resourceIds: postObject.resource_ids,
            presetId: null,
            extend: null,
          });
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteDeclarations({ commit, dispatch }, declarationIds) {
      let promises = [];
      declarationIds.forEach((element) => {
        commit("loader/setScreenLoading", true, { root: true });
        const url = `${apiEndpoints.company.bookings}/${element}`;
        const promise = new Promise((resolve) => {
          return httpServiceAuth
            .delete(url)
            .catch((error) => {
              if (error.response) {
                errorHandler(error.response);
              }
            })
            .finally(() => {
              resolve(true);
              commit("loader/setScreenLoading", false, { root: true });
            });
        });
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        commit("setBookingsPresets", null);
        commit("setSelectedDeclarations", {});
        dispatch("getBookings", {
          resourceIds: null,
          presetId: null,
          extend: null,
        });
      });
    },
    bookingCost({ commit }, values) {
      commit("setCost", null);
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(apiEndpoints.company.declarationCost, values)
        .then((response) => {
          commit("setCost", response.data);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    showGrid({ commit }) {
      let url = apiEndpoints.company.showGrid;
      return httpServiceAuth
        .get(url)
        .then((response) => {
          commit("setShowGrid", response);
        })
        .catch(() => {});
    },
  },
};
